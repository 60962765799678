import React, { useEffect, useState } from "react";
import styles from "./Details.module.css";
import { DetailsHeader } from "../../components/Details/DetailsHeader";
import { BuyTickets } from "../../components/Details/Buy Tickets/BuyTickets";
import { Playlist } from "../../components/Details/Playlists/Playlist";
import { useNavigate, useParams } from "react-router-dom";

import { FullScreenLoader } from "../../components/fullScreen loader/FullScreenLoader";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { generateTokenHandler } from "../../utils/json-web";
import SeriesEpisodesList from "../../components/Details/Series Details/SeriesEpisodesList";
export const Details = ({ getToast, getDeviceType }) => {
  const [hasbuyed, setHasbuy] = useState(false);
  const { video_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [detailedData, setDetailedData] = useState({});
  const [cbfc_Rating, setCbfc_Rating] = useState(null);
  const [seasonNumber, setSeasonNumber] = useState(1);
  const [backgroundImage, setbackgrondImage] = useState("");
  const [isSeries , setIsSeries] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  // getting token
  const authUser = localStorage.getItem("authUser");

  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  // store the payment status
  const [paymentStatus, setPaymentStatus] = useState(null);

  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }
  // get video details
  const getVideoDetailsById = async () => {
    console.log("getVideo Details By Id");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };

    console.log(headers.authorization);
    console.log(headers.devicetype);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    console.log("headers", headers);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `getVideoDetailsById/${video_id}`,
      headers: headers,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        if (res?.data?.data) {
          if(res?.data?.data?.isSeries){
            const sortedSeasons = res?.data?.data?.seasons?.sort((a, b) => a.seasonNumber - b.seasonNumber);
            console.log("sorted season", sortedSeasons);
            let seasonsData = res?.data?.data; 
            seasonsData.seasons = sortedSeasons;  
            setDetailedData(seasonsData);
          }
          else{
            setDetailedData(res?.data?.data);
          }
        }
        setbackgrondImage(encodeURI(res?.data?.data?.backgroundImage?.file));
        console.log("cbfc reating kahfhsiasiasidasdasdashdashkddhaio", res?.data?.data?.seasons );
        
        if(res?.data?.data?.isSeries){
          let season = res?.data?.data?.seasons?.find((item)=> item?.seasonNumber === seasonNumber);
          console.log("sessons", season);
          
          setCbfc_Rating(season?.cbfc_Rating || null)
          console.log("cbfc reating", season?.cbfc_Rating);
          
          
        }
        console.log(
          "res?.data?.data?.backgroundImage?.file: ",
          encodeURI(res?.data?.data?.backgroundImage?.file)
        );
       
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  // handling the payment status of user
  const getPaymentStatus = async () => {
    console.log("get Payment status--------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    console.log(headers.authorization);
    console.log(headers.devicetype);
    console.log(deviceDetailsFromApp);
    console.log(deviceDetailsFromApp?.deviceId);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    console.log("isSeries", isSeries);
    let series = JSON.parse(sessionStorage.getItem('isSeries'))

    let url =  movieBaseUrl + "getPaymentStatusForVideo_v3" + `?videoId=${video_id}&isSeries=${series ?'true' : 'false'}`;
    if(series){
      url = movieBaseUrl + "getPaymentStatusForVideo_v3" + `?videoId=${video_id}&isSeries=${series ?'true' : 'false'}&seasonNumber=${seasonNumber}`
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:url,
        // movieBaseUrl + "getPaymentStatusForVideo_v3" + `?videoId=${video_id}&isSeries=${series ?'true' : 'false'} ${series && "&seasonNumber=" + seasonNumber}`,
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          setPaymentStatus(res.data.data);
          // getToast(res.data.message, "top-right", "success");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  useEffect(() => {
    // call the getVideoDetails by id api
    getVideoDetailsById();
    getPaymentStatus();
  }, [video_id, seasonNumber]);

  return loader ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <FullScreenLoader />
    </div>
  ) : (
    <div
      className={styles.details_container}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}

      // style={{
      //   // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
      //   // backgroundSize: "cover",
      //   // backgroundPosition: "center",
      //   // backgroundAttachment: "fixed",
      //   backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${backgroundImage})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   // backgroundAttachment: "scroll", // Allows the background to scroll instead of being fixed
      //  height:'94vh',
      //   width: "100%", // Full width
      // }}
    >
      <div className={styles.dark_overlay}>
        <DetailsHeader
          detailedData={detailedData}
          getToast={getToast}
          deviceDetailsFromApp={deviceDetailsFromApp}
          paymentStatus={paymentStatus}
          getDeviceType={getDeviceType}
          seasonNumber ={seasonNumber} 
        />
        {/* buy tickets if viewer hasn't buy the ticket yet */}
        {hasbuyed ? (
          <Playlist />
        ) : (
          <BuyTickets
            paymentStatus={paymentStatus}
            getToast={getToast}
            getDeviceType={getDeviceType}
            detailedData={detailedData}
            deviceDetailsFromApp={deviceDetailsFromApp}
            seasonNumber ={seasonNumber} 

          />
        )}
        {detailedData?.isSeries && (
          <SeriesEpisodesList
          getPaymentStatus={()=>getPaymentStatus()}
          cbfc_Rating ={cbfc_Rating}
          seasonNumber ={seasonNumber} 
          setSeasonNumber={setSeasonNumber}
            detailedData={detailedData}
            getToast={getToast}
            deviceDetailsFromApp={deviceDetailsFromApp}
            paymentStatus={paymentStatus}
            getDeviceType={getDeviceType}
          />
          
        )}
      </div>
    </div>
  );
};
