import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./EpisodeCard.module.css";
import { BuyTickets } from "../../Buy Tickets/BuyTickets";
import BuyTicketsForEpisode from "../../Buy Tickets/BuyTicketsForEpisode";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EpisodeCard = ({
  imageSrc,
  title,
  releaseDate,
  duration,
  description,
  cast,
  crew,
  isFreeEpisode,
  isPrimeEpisode,
  paymentStatus,
  getDeviceType,
  getToast,
  detailedData,
  deviceDetailsFromApp,
  episode,
  seasonNumber,
  cbfc_Rating
}) => {
  const [deviceType, setDeviceType] = useState("laptop");
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  // Update deviceType based on screen width
  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth <= 480) {
        setDeviceType("mobile");
      } else if (window.innerWidth >= 481 && window.innerWidth <= 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("laptop");
      }
    };
    updateDeviceType();
    window.addEventListener("resize", updateDeviceType);
    return () => window.removeEventListener("resize", updateDeviceType);
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const showMoreToggle = () => {
    setShowMore((e) => !e);
  };

  // Define layouts based on device type
  const renderContent = () => {
    switch (deviceType) {
      case "mobile":
        return (
          <div
            className={`${styles.mobileCard} ${
              isExpanded ? styles.mobileExpanded : ""
            }`}
          >
            <div className={styles.mobileHeader} onClick={toggleExpand}>
              <div className={styles.mobileTitleWrapper}>
                <label className={styles.mobileEpisodeLabel}>{title}</label>
              </div>
              {!isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                    />
                  </svg>
                </span>
              )}
              {isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </span>
              )}
            </div>

            {isExpanded && (
              <div className={styles.mobileDetails}>
                <div className={styles.mobileContainer}>
                  <div className={styles.mobileImageContainer}>
                    <img
                      src={imageSrc}
                      alt="Episode Thumbnail"
                      className={styles.mobileImage}
                    />
                  </div>
                  <div className={styles.mobileInfo}>
                    <span className={styles.mobileDuration}>{duration}</span>
                    {cbfc_Rating && <span className={styles.mobileIcon}>{cbfc_Rating}</span> } {" "} 
                    {/* Placeholder for age rating icon */}
                  </div>
                </div>
                <div className={styles.mobileContent}>
                  {/* <p className={styles.mobileDescription}>{description}</p> */}
                  <p className={styles.mobileDescription}>
                    {showMore ? description : `${description.slice(0, 100)}...`}
                    <span
                      className={styles.toggleButton}
                      onClick={showMoreToggle}
                    >
                      {showMore ? " See Less" : " See More"}
                    </span>
                  </p>
                  <div className={styles.laptopCastAndCrew}>
                    {cast && cast?.length >= 1 && (
                      <div className={styles.laptopCastBadge}>
                        <div className={styles.laptopCastBadge}> { t("Cast")} :</div>
                        {cast?.map((item, index) => (
                          <Badge pill bg="dark">
                          {item?.name} as {item?.character}
                          </Badge>
                        ))}
                      </div>
                    )}

                    {crew && crew?.length >= 1 && (
                      <div className={styles.laptopCastBadge}>
                        <div className={styles.laptopCastBadge}> { t("Crew")} :</div>
                        {crew?.map((item, index) => (
                          <Badge pill bg="dark">
                          {item?.name} ({item?.role})
                          </Badge>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <BuyTicketsForEpisode
                    paymentStatus={paymentStatus}
                    getToast={getToast}
                    getDeviceType={getDeviceType}
                    detailedData={detailedData}
                    deviceDetailsFromApp={deviceDetailsFromApp}
                    seasonNumber={seasonNumber}
                    episode={episode}
                  />
                </div>
              </div>
            )}
          </div>
        );
      case "tablet":
        return (
          <div
            className={`${styles.tabletCard} ${
              isExpanded ? styles.tabletExpanded : ""
            }`}
          >
            <div className={styles.tabletHeader} onClick={toggleExpand}>
              <div className={styles.tabletTitleWrapper}>
                <label className={styles.tabletEpisodeLabel}>{title}</label>
              </div>
              {!isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                    />
                  </svg>
                </span>
              )}
              {isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </span>
              )}
            </div>

            {isExpanded && (
              <div className={styles.tabletDetails}>
                <div className={styles.tabletContainer}>
                  <div className={styles.tabletImageContainer}>
                    <img
                      src={imageSrc}
                      alt="Episode Thumbnail"
                      className={styles.tabletImage}
                    />
                  </div>
                  <div>
                    <div className={styles.tabletInfo}>
                      <span className={styles.tabletDuration}>{duration}</span>
                      {cbfc_Rating && <span className={styles.mobileIcon}>{cbfc_Rating}</span> } {" "}
                      {/* Placeholder for age rating icon */}
                    </div>
                    <div className={styles.tabletContent}>
                      <p className={styles.tabletDescription}>{description}</p>
                      <div className={styles.laptopCastAndCrew}>
                        {cast && cast?.length >= 1 && (
                          <div className={styles.laptopCastBadge}>
                            <div className={styles.laptopCastBadge}>{t("Cast")} :</div>
                            {cast?.map((item, index) => (
                              <Badge pill bg="dark">
                              {item?.name} as {item?.character}
                              </Badge>
                            ))}
                          </div>
                        )}

                        {crew && crew?.length >= 1 && (
                          <div className={styles.laptopCastBadge}>
                            <div className={styles.laptopCastBadge}>{t("Crew")} :</div>
                            {crew?.map((item, index) => (
                              <Badge pill bg="dark">
                              {item?.name} ({item?.role})
                              </Badge>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <BuyTicketsForEpisode
                    paymentStatus={paymentStatus}
                    getToast={getToast}
                    getDeviceType={getDeviceType}
                    detailedData={detailedData}
                    deviceDetailsFromApp={deviceDetailsFromApp}
                    seasonNumber={seasonNumber}
                    episode={episode}
                  />
                </div>
              </div>
            )}
          </div>
        );
      case "laptop":
      default:
        return (
          <div className={styles.laptopCard}>
            <div className={styles.laptopHeader}>
              <img
                src={imageSrc}
                alt="Episode Thumbnail"
                className={styles.laptopImage}
              />
              <div className={styles.laptopContent}>
                <h4 className={styles.laptopEpisodeLabel}>{title}</h4>
                <div className={styles.laptopInfo}>
                  {/* <label className={styles.laptopDate}>{releaseDate}</label> */}
                  <span className={styles.laptopDuration}>{duration}</span>
                  {cbfc_Rating && <span className={styles.mobileIcon}>{cbfc_Rating}</span> } {" "}
                </div>
                <p className={styles.laptopDescription}>{description}</p>

                <div className={styles.laptopCastAndCrew}>
                  {cast && cast?.length >= 1 && (
                    <div className={styles.laptopCastBadge}>
                      <div className={styles.laptopCastBadge}>{t("Cast")} :</div>
                      {cast?.map((item, index) => (
                        <Badge pill bg="dark">
                          {item?.name} as {item?.character}
                        </Badge>
                      ))}
                    </div>
                  )}

                  {crew && crew?.length >= 1 && (
                    <div className={styles.laptopCastBadge}>
                      <div className={styles.laptopCastBadge}>{t("Crew")} :</div>
                      {crew?.map((item, index) => (
                        <Badge pill bg="dark">
                          {item?.name} ({item?.role})
                        </Badge>
                      ))}
                    </div>
                  )}
                </div>
                {/* { (
                  <div className={styles.laptopFreeEpisode}>First episode free</div>
                )}
                { (
                  <div className={styles.laptopPrimeEpisode}>
                    <span>🌟</span> Watch with a Prime membership
                  </div>
                )} */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <BuyTicketsForEpisode
                paymentStatus={paymentStatus}
                getToast={getToast}
                getDeviceType={getDeviceType}
                detailedData={detailedData}
                deviceDetailsFromApp={deviceDetailsFromApp}
                seasonNumber={seasonNumber}
                episode={episode}
              />
            </div>
          </div>
        );
    }
  };

  return <div className={styles.card}>{renderContent()}</div>;
};

EpisodeCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  releaseDate: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isFreeEpisode: PropTypes.bool,
  isPrimeEpisode: PropTypes.bool,
};

EpisodeCard.defaultProps = {
  isFreeEpisode: false,
  isPrimeEpisode: false,
};

export default EpisodeCard;
