import React, { useRef, useState, useEffect } from "react";
import styles from "./DetailsHeader.module.css";
import poster from "../../assets/new-images/Posters/bg_1.webp";
import star from "../../assets/new-images/Home/star.png";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VideoDurationCalculator from "../VideoDurationCalculator/VideoDurationCalculator";
import { generateTokenHandler } from "../../utils/json-web";

export const DetailsHeader = ({
  detailedData,
  deviceDetailsFromApp,
  getToast,
  getDeviceType,
  seasonNumber
}) => {
  const {
    videoId,
    keyArt2 = {},
    title = "",
    cbfc_Rating = "",
    director = "",
    genre = [],
    duration = 0,
    cast = [],
    synopsis = "",
    isSeries = "",
    trailers = "",
    seasons = [],
  } = detailedData;
  const [trailerDuration, setTrailerDuration] = useState(null);
  const navigate = useNavigate();
  const videoRef = useRef();
  const [show, setShow] = useState(false);
  const authUser = localStorage.getItem("authUser");
  const { t } = useTranslation();
  const seriesDuration = seasons[seasonNumber-1]?.totalDuration;
  const trailersList = isSeries
    ? seasons[seasonNumber-1]?.trailers
    : trailers;
  // getting favourites from storage;
  let favourites = JSON.parse(localStorage.getItem("favourites")) || [];

  const val = sessionStorage.getItem("banner") == videoId;
  console.log("val: ", val);

  const [isFav, setIsFav] = useState(
    favourites.includes(detailedData?.videoId)
  );

  const userAgent = window.navigator.userAgent.toLowerCase();
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }
  const callbackFn = (duration) => {
    console.log("/////////////////////////////////////", duration);
    setTrailerDuration(duration);
  };

  const handleTrailerAndVideoClick = async (val = false, play = false) => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };

    console.log(headers.authorization);
    console.log(headers.devicetype);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    console.log("headers", headers);

    let data;
    if (isSeries) {
      data = {
        videoId,
        trailer: play,
        seasonNumber: seasonNumber,
        banner: val,
      };
    } else {
      data = {
        videoId,
        trailer: play,
        banner: val,
      };
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `videoClickOrTrailerPlayed`,
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };
  const [currentTrailer, setCurrentTrailer] = useState(0);
  const seriesCBFCRating = seasons[seasonNumber-1]?.cbfc_Rating;
  const handleClose = () => {
    setCurrentTrailer(0);
    setShow(false);
  };

  const handleTrailerOpen = () => {
    setShow(true);
    handleTrailerAndVideoClick(val, true);
    if (videoRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        videoRef.current.requestFullscreen();
      }
    }
  };

  const handleShare = () => {
    const currentUrl = window.location.href;

    if (!deviceDetailsFromApp) {
      navigator.clipboard.writeText(currentUrl);
      getToast(
        t("Link copied! Share this link with your loved ones."),
        "top-right",
        "success"
      );
      return;
    }

    if (
      deviceDetailsFromApp?.deviceId &&
      (getDeviceType() == "laptop" || getDeviceType() == "tv")
    ) {
      var encodedJsonString = encodeURIComponent(currentUrl);
      window.location.href = `app://shareContent?data=${encodedJsonString}`;
      return;
    }
    window?.flutter_inappwebview?.callHandler("shareContent", {
      currentUrl,
      contentName: title,
    });
  };

  // handle add favourite
  const handleAddFavourite = async () => {
    let slug = await generateTokenHandler();
    console.log("add to fav--------");
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    console.log(headers.authorization);
    console.log(headers.devicetype);
    console.log(deviceDetailsFromApp);
    console.log(deviceDetailsFromApp?.deviceId);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + "favourite/add",
      headers: headers,
      data: {
        videoId: detailedData?.videoId,
      },
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          getToast(res.data.message, "top-right", "success");

          favourites.push(detailedData?.videoId);

          localStorage.setItem("favourites", JSON.stringify(favourites));
          setIsFav(true);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  const handleDeleteFavourite = async () => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };
    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log("headers", headers);
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `/favourite/remove`,
      headers: headers,
      data: {
        videoId: detailedData?.videoId,
      },
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        if (res?.data?.status && res?.data?.response_code == 200) {
          getToast(res?.data?.message, "top-right", "success");

          const newfav = favourites?.filter(
            (el) => el != detailedData?.videoId
          );

          localStorage.setItem("favourites", JSON.stringify(newfav));
          setIsFav(false);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  function isNullOrEmptyTrailer(value) {
    if (value === null || value === undefined) {
      return true;
    }

    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }

    if (typeof value === "string" && value.trim() === "") {
      return true;
    }

    return false;
  }

  useEffect(() => {
    handleTrailerAndVideoClick(val, false);
  }, [seasonNumber]);

  return (
    <div className={styles.details_header_container}>
      <VideoDurationCalculator
        filePath={
          !isNullOrEmptyTrailer(trailersList) &&
          trailersList[currentTrailer]?.file
        }
        callbackFunction={callbackFn}
      />
      <div className={styles.details_header}>
        <img src={keyArt2?.file} alt="" />
        <div>
          <h1>{title}</h1>
          <div>
            <h4>{isSeries ? seriesCBFCRating : cbfc_Rating}</h4>
            <h4>{genre?.join("/ ")}</h4>
            <h4>
              {" "}
              {isSeries
                ? Math.floor((seriesDuration || 0) / 60) +
                  "." +
                  Math.floor((seriesDuration || 0) % 60) +
                  " " +
                  "min"
                : Math.floor((duration || 0) / 60) +
                  "." +
                  Math.floor((duration || 0) % 60) +
                  " " +
                  "min"}
            </h4>
          </div>
          {/* <div className={styles.rating_box}>
            4.5
            <img src={star} />
          </div> */}
        </div>
      </div>
      <div className={styles.details_synopsis}>{synopsis}</div>

      <div className={styles.cast_screens}>
        <h6>
          {t("Cast")} : {cast?.map((el) => el.name)?.join(", ")}
        </h6>
        <h6>
          {t("Director")} : {director}
        </h6>
      </div>

      <div className={styles.watch_trailer_btn}>
        <button
          onClick={() => handleTrailerOpen()}
          disabled={isNullOrEmptyTrailer(trailersList)}
          className={`${
            isNullOrEmptyTrailer(trailersList)
              ? styles.trailer_disabled_btn
              : ""
          }`}
        >
          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                fill="#000000"
                d="M106.854 106.002a26.003 26.003 0 0 0-25.64 29.326c16 124 16 117.344 0 241.344a26.003 26.003 0 0 0 35.776 27.332l298-124a26.003 26.003 0 0 0 0-48.008l-298-124a26.003 26.003 0 0 0-10.136-1.994z"
              ></path>
            </g>
          </svg>
          {!isNullOrEmptyTrailer(trailersList)
            ? `${t("Watch Trailers")} (
          ${
            Math.floor((trailerDuration || 0) / 60) +
            "." +
            Math.floor((trailerDuration || 0) % 60) +
            " " +
            "min"
          } )`
            : t(`Trailer is Not Available`)}
        </button>

        <button onClick={handleShare}>
          <svg
            viewBox="-1 0 28 28"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#50e4ad"
            stroke="#50e4ad"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <title>share</title> <desc>Created with Sketch Beta.</desc>{" "}
              <defs> </defs>{" "}
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                {" "}
                <g
                  id="Icon-Set-Filled"
                  transform="translate(-314.000000, -728.000000)"
                  fill="#50e4ad"
                >
                  {" "}
                  <path
                    d="M333,744 C331.23,744 329.685,744.925 328.796,746.312 L323.441,743.252 C323.787,742.572 324,741.814 324,741 C324,740.497 323.903,740.021 323.765,739.563 L329.336,736.38 C330.249,737.37 331.547,738 333,738 C335.762,738 338,735.762 338,733 C338,730.238 335.762,728 333,728 C330.238,728 328,730.238 328,733 C328,733.503 328.097,733.979 328.235,734.438 L322.664,737.62 C321.751,736.631 320.453,736 319,736 C316.238,736 314,738.238 314,741 C314,743.762 316.238,746 319,746 C320.14,746 321.179,745.604 322.02,744.962 L328.055,748.46 C328.035,748.64 328,748.814 328,749 C328,751.762 330.238,754 333,754 C335.762,754 338,751.762 338,749 C338,746.238 335.762,744 333,744"
                    id="share"
                  >
                    {" "}
                  </path>{" "}
                </g>{" "}
              </g>{" "}
            </g>
          </svg>{" "}
          {t("Share")}
        </button>

        <button
          className={`${styles.wishlist_btn} ${
            isFav ? styles.color_green : ""
          }`}
          onClick={isFav ? handleDeleteFavourite : handleAddFavourite}
          // disabled={isFav}
        >
          {" "}
          {isFav ? (
            <svg
              width="32"
              height="30"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#017bf4"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
                  fill="#017bf4"
                ></path>{" "}
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="30"
              viewBox="0 0 22 20"
              fill="none"
            >
              <path
                d="M0 6.24194C0.0382106 3.09062 2.47717 0.377271 5.5219 0.0593537C7.39422 -0.136583 9.08574 0.351339 10.6673 1.33102C10.9357 1.49719 11.1044 1.48758 11.3672 1.32718C13.219 0.190939 15.1938 -0.344046 17.3336 0.236081C21.1184 1.26283 22.9637 5.14316 21.4977 8.91783C20.8919 10.4776 19.973 11.8069 18.7679 12.9278C16.302 15.2224 13.8257 17.5054 11.3663 19.8087C11.0829 20.0738 10.9292 20.0565 10.6542 19.7991C8.18266 17.4862 5.68406 15.2041 3.21901 12.8846C1.68406 11.44 0.575023 9.71118 0.166822 7.57124C0.082945 7.13422 0.054054 6.68568 0 6.24194ZM6.24884 1.52696C6.08667 1.54521 5.79403 1.57499 5.5014 1.61148C3.00652 1.9198 1.21435 4.35941 1.5685 6.92388C1.83784 8.87365 2.80242 10.4219 4.16962 11.7176C6.32898 13.7634 8.52842 15.7631 10.698 17.7974C10.9525 18.0356 11.082 18.0299 11.3318 17.7955C13.5126 15.7507 15.7157 13.7308 17.8966 11.6859C18.9459 10.7024 19.7363 9.52293 20.1948 8.1312C21.4138 4.42856 18.7605 1.10819 14.9925 1.58459C13.6421 1.75556 12.4651 2.31743 11.3914 3.13576C11.1034 3.35475 10.9189 3.34995 10.6319 3.13C9.37279 2.16376 7.97484 1.56154 6.2479 1.52792L6.24884 1.52696Z"
                fill="white"
              />
            </svg>
          )}
        </button>

        {/* <div className={styles.trailer_duration}>
          {Math.floor((trailerDuration || 0) / 60) +
            "." +
            Math.floor((trailerDuration || 0) % 60) +
            " " +
            "min"}
        </div> */}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header className={styles.video_container_header}>
          <div className={styles.video_container}>
            <video
              controlsList="nodownload"
              ref={videoRef}
              src={trailersList[currentTrailer]?.file}
              controls
            ></video>
          </div>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer
          className={`${styles.watch_trailer_btn} ${styles.trailers_btns}`}
        >
          <button
            onClick={handleClose}
            style={{ display: currentTrailer == 0 ? "none" : "" }}
          >
            {currentTrailer != 0 ? t("Close") : ""}
          </button>
          <button
            onClick={
              currentTrailer == 0
                ? handleClose
                : () => setCurrentTrailer(currentTrailer - 1)
            }
          >
            {currentTrailer == 0 ? t("Close") : t("Previous")}
          </button>
          <button
            disabled={currentTrailer == trailersList?.length - 1}
            className={
              currentTrailer == trailersList?.length - 1
                ? styles.disabled_btn
                : ""
            }
            onClick={() => setCurrentTrailer(currentTrailer + 1)}
          >
            {t("Next")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
